<template>
  <editor-header-card :title="title" class="dossier_header">
    <p>
      Dossiernummer: {{ dossiernumber }} - <b>{{ fullName }}</b> <br />
      Telefoonnummer(s): {{ phonenumbers }}<br />
      email: {{ email }}
    </p>
  </editor-header-card>
</template>

<script>
import EditorHeaderCard from '@/components/crud/EditorHeaderCard';
export default {
  components: {
    EditorHeaderCard
  },
  props: {
    dossierInfo: Object
  },
  computed: {
    title() {
      const title = 'Dossier voor';
      return title;
    },
    fullName: {
      get() {
        return !this.dossierInfo ? '' : this.dossierInfo.fullName;
      }
    },
    phonenumbers: {
      get() {
        let numbers = '';
        let separator = '';
        if (this.dossierInfo.mobile) {
          numbers = this.dossierInfo.mobile;
          separator = ' / ';
        }
        if (this.dossierInfo.telephone) {
          numbers = numbers + separator + this.dossierInfo.telephone;
        }
        return numbers;
      }
    },
    email: {
      get() {
        return !this.dossierInfo ? '' : this.dossierInfo.email;
      }
    },
    dossiernumber: {
      get() {
        return !this.dossierInfo ? '' : this.dossierInfo.id;
      }
    }
  }
};
</script>
