import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';

export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'Loan',
    returnTo: 'Loans'
  }),
  methods: {
    getDependencies(data) {
      return [
        { entity: 'applicant' },
        { entity: 'loanType' },
        { entity: 'status' },
        { entity: 'reason' },
        { entity: 'delay' },
        { entity: 'debtStep' }
      ];
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData,
    termcalculation() {
      const url =
        window.API_URL +
        '/loan/CalculateTerms?' +
        new URLSearchParams({
          amount: this.$refs.editor.form.amount,
          repaymentamount: this.$refs.editor.form.repaymentAmount
        });
      const result = fetch(url);
      console.log(url);
      console.log(result);
      if (!this.$refs.editor.form.terms) {
        const amount = this.$refs.editor.form.amount;
        const repaymentamount = this.$refs.editor.form.repaymentAmount;
        if (!(repaymentamount === '' || repaymentamount === 0)) {
          this.$refs.editor.form.terms = Math.ceil(amount / repaymentamount);
        }
      }
    },
    thlcalculation() {
      const url =
        window.API_URL +
        '/loan/CalulateThlDate?' +
        new URLSearchParams({
          startdate: this.$refs.editor.form.firstDate,
          terms: this.$refs.editor.form.terms
        });
      const result = fetch(url, {
        mode: 'no-cors'
      });
      console.log(url);
      console.log(result);
      if (this.$refs.editor.form.thlDate === null) {
        const firstdate = this.$refs.editor.form.firstDate;
        const terms = this.$refs.editor.form.terms;
        if (!(firstdate === '' || terms === '')) {
          const newdate = new Date(firstdate);
          this.$refs.editor.form.thlDate = new Date(newdate.setMonth(newdate.getMonth() + terms));
        }
      }
    }
  }
};
